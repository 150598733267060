import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { GalleryIndicator } from "scmp-app/components/gallery/indicator";
import { IndicatorIcon } from "scmp-app/components/gallery/indicator/styles";

export const StyledGalleryIndicator = styled(GalleryIndicator)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  inline-size: 32px;
  block-size: 32px;
  margin: 8px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 32px;
    block-size: 32px;
    margin: 8px;
  }

  ${IndicatorIcon} {
    ${theme.breakpoints.up("tablet")} {
      inline-size: 16px;
      block-size: 16px;
    }
  }
`;
