import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { article as articleData } from "shared/data";

import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import type { Props as AuthorAvatarProps } from "scmp-app/components/author/author-avatar";
import { AuthorAvatarContextProvider } from "scmp-app/components/author/author-avatar/contexts";
import { useContentHelper } from "scmp-app/components/content/hooks";
import type { contentAuthorImageContent$key } from "scmp-app/queries/__generated__/contentAuthorImageContent.graphql";

import { AvatarContainer, StyledAuthorAvatar } from "./styles";

type Props = Pick<AuthorAvatarProps, "onClick" | "withoutLink"> & {
  className?: string;
  ignoreAuthorType?: boolean;
  isMultiple?: boolean;
  onClick?: (entityUuid: string) => void;
  reference: contentAuthorImageContent$key;
  withBorder?: boolean;
};

export const ContentAuthorImage: FunctionComponent<Props> = ({
  className,
  ignoreAuthorType,
  isMultiple,
  onClick,
  reference: reference_,
  withBorder = true,
  withoutLink,
}) => {
  const content = useFragment(
    graphql`
      fragment contentAuthorImageContent on Content {
        authors {
          entityId
          ...authorAvatarAuthor
          ...entityLink
        }
        ...hooksContentHelperContent
        ...helpersGetArticleTypeArticle
      }
    `,
    reference_,
  );

  const articleType = getArticleType(content);
  const { isEditorialOpinionType, isScmpOpinionType } = useContentHelper(content);
  if (!(isScmpOpinionType || isEditorialOpinionType) && !ignoreAuthorType) return null;

  const [mainAuthor, ...rest] = (content?.authors ?? []).filter(author => notEmpty(author));
  const authors = isMultiple ? [mainAuthor, ...rest] : [mainAuthor];

  if (authors.length === 0) return null;

  return (
    <AuthorAvatarContextProvider
      showBorderColor={withBorder && articleType === articleData.types.column.entityId}
    >
      <AvatarContainer className={className}>
        {authors.map((author, index) =>
          author ? (
            <StyledAuthorAvatar
              $zIndex={index}
              height={40}
              key={author.entityId}
              onClick={onClick}
              reference={author}
              width={40}
              withoutLink={withoutLink}
            />
          ) : null,
        )}
      </AvatarContainer>
    </AuthorAvatarContextProvider>
  );
};
