import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { formatDistanceToNow, getReadingTime, toISOString } from "shared/lib/utils";

import { Spacer } from "scmp-app/components/common/spacer";
import { isArticleVideoAsHero } from "scmp-app/components/content/content-cover-video/helpers";
import { getContentTypeName } from "scmp-app/components/content/helpers";
import type { CommentWidgetPosition } from "scmp-app/components/tracking/google-analytics-4/types";
import type { contentActionBarContent$key } from "scmp-app/queries/__generated__/contentActionBarContent.graphql";

import { ContentTypeIcon } from "./content-type-icon";
import type { StyledArticleCommentProps } from "./styles";
import {
  FlexContainer,
  IconContainer,
  StyledArticleComment,
  StyledContentBookmark,
  VideoIcon,
} from "./styles";

export type DisplayDateVariant = "publishedDate" | "readingTime" | "updatedDate";

export type Props = {
  className?: string;
  commentVariant?: StyledArticleCommentProps["$variant"];
  displayDateVariant?: DisplayDateVariant;
  isBookmarkVisible?: boolean;
  isCommentsVisible?: boolean;
  isIconVisible?: boolean;
  reference: contentActionBarContent$key;
  shouldShowContentTypeIcon?: boolean;
  trackingPosition?: CommentWidgetPosition;
};

export const ContentActionBar: FunctionComponent<Props> = ({
  className,
  commentVariant = "default",
  displayDateVariant = "updatedDate",
  isBookmarkVisible = true,
  isCommentsVisible = true,
  isIconVisible = true,
  reference: reference_,
  shouldShowContentTypeIcon,
  trackingPosition = "rail",
}) => {
  const content = useFragment(
    graphql`
      fragment contentActionBarContent on Content {
        entityId
        createdDate
        updatedDate
        publishedDate
        readingTime
        ...articleCommentArticle
        ...helpersIsArticleVideoAsHeroArticle
        ...helpersGetContentTypeNameContent
        ... on Article {
          hasVideoContent
        }
      }
    `,
    reference_,
  );

  const contentTypeName = getContentTypeName(content);

  const handleRenderDisplayDateTime = () => {
    const { createdDate, publishedDate, readingTime, updatedDate } = content;

    if (displayDateVariant === "readingTime") {
      const readingTimeText = getReadingTime(readingTime ?? 0, "-MIN READ", "-MIN READ");
      return <time>{readingTimeText}</time>;
    }

    const displayDateTime =
      displayDateVariant === "publishedDate" ? (publishedDate ?? createdDate) : updatedDate;
    if (!displayDateTime) return null;
    return (
      <time dateTime={toISOString(displayDateTime)} suppressHydrationWarning>
        {formatDistanceToNow(displayDateTime)}
      </time>
    );
  };

  return (
    <FlexContainer className={className}>
      {handleRenderDisplayDateTime()}
      {/*  Cspell:ignore videocam */}

      {!shouldShowContentTypeIcon &&
        isIconVisible &&
        (content.hasVideoContent || isArticleVideoAsHero(content)) && (
          <VideoIcon color="#d7d7d7" size="20px">
            videocam
          </VideoIcon>
        )}
      {shouldShowContentTypeIcon && <ContentTypeIcon contentTypeName={contentTypeName} />}
      <Spacer />
      <IconContainer>
        {isCommentsVisible && (
          <StyledArticleComment
            $variant={commentVariant}
            reference={content}
            trackingPosition={trackingPosition}
          />
        )}

        {isBookmarkVisible && (
          <StyledContentBookmark
            entityId={content.entityId}
            ga4Events={{
              click: {
                action: "click",
                category: "bookmark",
                customized_parameters: {
                  action_type: "add",
                  article_id: content.entityId,
                  trigger_point: "article_overlay",
                },
                subcategory: "",
              },
            }}
          />
        )}
      </IconContainer>
    </FlexContainer>
  );
};

ContentActionBar.displayName = "ContentActionBar";
