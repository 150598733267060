import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext } from "react";

type ContextValue = {
  showBorderColor?: boolean;
};

type Props = ContextValue & {
  children: ReactNode;
};

const AuthorAvatarContext = createContext<ContextValue>({});

export const AuthorAvatarContextProvider: FunctionComponent<Props> = ({
  children,
  showBorderColor = false,
}) => (
  <AuthorAvatarContext.Provider
    value={{
      showBorderColor,
    }}
  >
    {children}
  </AuthorAvatarContext.Provider>
);

export const useAuthorAvatarContext = () => useContext(AuthorAvatarContext);
