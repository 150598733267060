/**
 * @generated SignedSource<<8227d8cdf4719c8bc1de23326fc7bf12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentCoverImageContent$data = {
  readonly headline: string | null | undefined;
  readonly images: ReadonlyArray<{
    readonly landscape250x99?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1200x1200?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1200x800?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1280x720?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size320x180?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size320x240?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size500x500?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size540x360?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size640x360?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size660w?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size768x768?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size800x1200?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size80x80?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly title: string | null | undefined;
    readonly type: string | null | undefined;
    readonly url: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "contentCoverImageContent";
};
export type contentCoverImageContent$key = {
  readonly " $data"?: contentCoverImageContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentCoverImageContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withLandscape250x99"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize1200x1200"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize1200x800"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize1280x720"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize320x180"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize320x240"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize500x500"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize540x360"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize640x360"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize660w"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize768x768"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize800x1200"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withSize80x80"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentCoverImageContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "condition": "withLandscape250x99",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "landscape250x99",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "landscape_250_99"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"landscape_250_99\"})"
            }
          ]
        },
        {
          "condition": "withSize1200x800",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size1200x800",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "1200x800"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"1200x800\"})"
            }
          ]
        },
        {
          "condition": "withSize1200x1200",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size1200x1200",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "1200x1200"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"1200x1200\"})"
            }
          ]
        },
        {
          "condition": "withSize1280x720",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size1280x720",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "wide_landscape"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"wide_landscape\"})"
            }
          ]
        },
        {
          "condition": "withSize640x360",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size640x360",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "wide_landscape_640_360"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"wide_landscape_640_360\"})"
            }
          ]
        },
        {
          "condition": "withSize768x768",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size768x768",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "768x768"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"768x768\"})"
            }
          ]
        },
        {
          "condition": "withSize540x360",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size540x360",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "540x360"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"540x360\"})"
            }
          ]
        },
        {
          "condition": "withSize320x240",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size320x240",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "320x240"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"320x240\"})"
            }
          ]
        },
        {
          "condition": "withSize320x180",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size320x180",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "320x180"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"320x180\"})"
            }
          ]
        },
        {
          "condition": "withSize80x80",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size80x80",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "80x80"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"80x80\"})"
            }
          ]
        },
        {
          "condition": "withSize500x500",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size500x500",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "500x500"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"500x500\"})"
            }
          ]
        },
        {
          "condition": "withSize800x1200",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size800x1200",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "portrait"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"portrait\"})"
            }
          ]
        },
        {
          "condition": "withSize660w",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "size660w",
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "660w"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "style(filter:{\"style\":\"660w\"})"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "d05c7f025f90612bad0214fe59cf0c52";

export default node;
