import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BaseImage } from "scmp-app/components/common/base-image";
import { EntityLink } from "scmp-app/components/entity-link";

import Placeholder from "./placeholder.svg";

export const StyledImage = styled(BaseImage)`
  inline-size: 100%;
  block-size: 100%;

  border-radius: 50%;

  background: #dadede;

  object-fit: cover;
  object-position: 50% 50%;
`;

export const StyledPlaceholder = styled(Placeholder)`
  border-radius: 50%;

  background: #dedede;

  filter: grayscale(100%);

  object-fit: cover;
  object-position: 50% 50%;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: inline-block;
`;

type Props = {
  $isColored?: boolean;
  $withBorderColor?: boolean;
};

export const Figure = styled.figure<Props>`
  ${props =>
    props.$withBorderColor &&
    css`
      border: 3px solid;
      border-color: #34b8b5;
    `}
  border-radius: 50%;

  ${StyledImage} {
    filter: ${props => (props.$isColored ? "none" : "grayscale(100%)")};
  }
`;
