import { BookmarkAction } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";

import { Container, StyledIconBookmark, StyledIconBookmarked } from "./styles";

type Props = {
  className?: string;
  isBookmarked?: boolean;
  onClick?: (
    action: BookmarkAction,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

export const Article20Bookmark: FunctionComponent<Props> = ({
  className,
  isBookmarked,
  onClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(isBookmarked ? BookmarkAction.UnBookmark : BookmarkAction.Bookmark, event);
  };

  return (
    <Container
      aria-label={isBookmarked ? "unbookmark" : "bookmark"}
      className={className}
      onClick={handleClick}
    >
      {isBookmarked ? <StyledIconBookmarked /> : <StyledIconBookmark />}
    </Container>
  );
};

Article20Bookmark.displayName = "Article20Bookmark";
