/**
 * @generated SignedSource<<f27478f4e4efab8b01a94468751dc2ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleCommentArticle$data = {
  readonly commentCount: number | null | undefined;
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  readonly " $fragmentType": "articleCommentArticle";
};
export type articleCommentArticle$key = {
  readonly " $data"?: articleCommentArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleCommentArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "15bb53239369dd472417e91b7e18e8e6";

export default node;
