/**
 * @generated SignedSource<<22694cf0250c44c4cd182bf9fa651bdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicCheckSuperTopic$data = {
  readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "topicCheckSuperTopic";
};
export type topicCheckSuperTopic$key = {
  readonly " $data"?: topicCheckSuperTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicCheckSuperTopic">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "topicCheckSuperTopic"
};

(node as any).hash = "f7f7bbcc084b0870895bcae7f65cfcdc";

export default node;
