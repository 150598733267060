import type { BookmarkAction } from "@product/scmp-sdk";
import { BookmarkButton } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import React, { useCallback } from "react";

import type { BookmarkClickEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useUserService } from "scmp-app/lib/user-service/hooks";

import { Article20Bookmark } from "./article-2.0";
import type { BookmarkLocation } from "./types";

export type Props = {
  className?: string;
  entityId: string;
  ga4Events?: { click: BookmarkClickEvent };
  location?: BookmarkLocation;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: "article-2.0" | "default";
};

export const ContentBookmark: FunctionComponent<Props> = ({
  className,
  entityId,
  ga4Events,
  location = "others",
  onClick,
  variant,
}) => {
  const { bookmarkAction, checkIsBookmarked } = useUserService();

  const handleClick = useCallback(
    (action: BookmarkAction, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      bookmarkAction(
        action,
        entityId,
        {
          ga4Events,
          location,
        },
        () => {
          onClick?.(event);
        },
      ),
    [entityId, ga4Events, bookmarkAction, location, onClick],
  );

  if (variant === "article-2.0") {
    return (
      <Article20Bookmark
        className={className}
        isBookmarked={checkIsBookmarked(entityId)}
        onClick={handleClick}
      />
    );
  }

  return (
    <BookmarkButton
      className={className}
      isBookmarked={checkIsBookmarked(entityId)}
      onClick={handleClick}
    />
  );
};

ContentBookmark.displayName = "ContentBookmark";
