import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { section as sectionData } from "shared/data/section";
import type { ResponsiveVariants } from "shared/lib/styles";

import { DefaultVideoDurationOverlay } from "scmp-app/components/video/video-duration-overlay/variants/default";
import type { StyledProps } from "scmp-app/components/video/video-duration-overlay/variants/default/styles";
import { HomepageVideoDurationOverlay } from "scmp-app/components/video/video-duration-overlay/variants/homepage";
import type { videoDurationOverlayVideo$key } from "scmp-app/queries/__generated__/videoDurationOverlayVideo.graphql";

export type IconSize = "hidden" | "large" | "small";
export type ResponsiveVariantsProps = {
  $responsiveVariants: ResponsiveVariants<Variant>;
};

type Props = PropsFromStyledProps<StyledProps> & {
  className?: string;
  iconSize?: IconSize;
  reference: videoDurationOverlayVideo$key;
  variant: Variant;
};

type Variant = "default" | "homepage";

export const VideoDurationOverlay: FunctionComponent<Props> = ({
  className,
  iconSize = "small",
  reference: reference_,
  responsiveVariants,
  variant = "default",
}) => {
  const video = useFragment(
    graphql`
      fragment videoDurationOverlayVideo on Video {
        duration
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference_,
  );

  const duration = video?.duration ?? "";
  const sections = video?.sections?.flatMap(sections => sections?.value ?? []) ?? [];
  const hasPodcastSection = sections?.some(
    section => section?.entityId === sectionData.video.podcasts.entityId,
  );

  switch (variant) {
    case "default":
      return (
        <DefaultVideoDurationOverlay
          className={className}
          duration={duration}
          responsiveVariants={responsiveVariants}
        />
      );
    case "homepage":
      return (
        <HomepageVideoDurationOverlay
          className={className}
          duration={duration}
          hasPodcastSection={hasPodcastSection}
          iconSize={iconSize}
        />
      );
  }
};

VideoDurationOverlay.displayName = "VideoDurationOverlay";
