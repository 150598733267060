import first from "lodash/first";
import { Fragment, type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentHelper } from "scmp-app/components/content/hooks";
import type { contentAuthorLinkContent$key } from "scmp-app/queries/__generated__/contentAuthorLinkContent.graphql";

import { AndSeparator, AuthorEntityLink, Authors, AuthorText, CommasSeparator } from "./styles";

type Props = {
  className?: string;
  ignoreAuthorType?: boolean;
  isEnableLink?: boolean;
  isMultiple?: boolean;
  onClick?: (entityUuid: string, entityId?: string) => void;
  reference: contentAuthorLinkContent$key;
  withAndSeparator?: boolean;
};

export const ContentAuthor: FunctionComponent<Props> = ({
  className,
  ignoreAuthorType,
  isEnableLink = true,
  isMultiple,
  onClick,
  reference: reference_,
  withAndSeparator,
}) => {
  const content = useFragment(
    graphql`
      fragment contentAuthorLinkContent on Content {
        authors {
          entityId
          entityUuid
          name
          ...entityLink
        }
        ...hooksContentHelperContent
      }
    `,
    reference_,
  );
  const authors = content?.authors;
  const authorsLength = authors?.length ?? 0;

  const renderCommaOrAnd = useCallback(
    (length: number, index: number) => {
      const lastIndex = length - 1;
      if (length <= 1 || index === lastIndex) return null;
      if (withAndSeparator && index === lastIndex - 1)
        return <AndSeparator key={index}>and</AndSeparator>;
      return <CommasSeparator key={index}>,</CommasSeparator>;
    },
    [withAndSeparator],
  );

  const handleRenderAuthor = useCallback(
    (author: NonNullable<typeof authors>[0]) => {
      if (!author) return null;

      return isEnableLink ? (
        <AuthorEntityLink
          className={className}
          onClick={event => {
            event.stopPropagation();
            onClick?.(author.entityUuid, author.entityId);
          }}
          reference={author}
        >
          {author.name}
        </AuthorEntityLink>
      ) : (
        <AuthorText className={className} onClick={() => onClick?.(author.entityUuid)}>
          {" "}
          {author.name}
        </AuthorText>
      );
    },
    [className, isEnableLink, onClick],
  );

  const multipleAuthors = useMemo(
    () =>
      authors?.map((author, index) => {
        if (!author) return null;

        const separator = renderCommaOrAnd(authorsLength, index);
        return (
          <Fragment key={author.entityId}>
            {handleRenderAuthor(author)}
            {separator}
          </Fragment>
        );
      }),
    [authors, authorsLength, handleRenderAuthor, renderCommaOrAnd],
  );

  const { isEditorialOpinionType, isScmpOpinionType } = useContentHelper(content);
  if (!(isScmpOpinionType || isEditorialOpinionType) && !ignoreAuthorType) return null;

  const mainAuthor = first(content.authors);
  if (!mainAuthor) return null;

  return isMultiple ? (
    <Authors className={className}>{multipleAuthors}</Authors>
  ) : (
    handleRenderAuthor(mainAuthor)
  );
};
