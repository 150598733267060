/**
 * @generated SignedSource<<3e78ffff9f9098226882896d85baadfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentLiveTagContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckHasLiveTagArticle">;
  readonly " $fragmentType": "contentLiveTagContent";
};
export type contentLiveTagContent$key = {
  readonly " $data"?: contentLiveTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentLiveTagContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentLiveTagContent",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckHasLiveTagArticle",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flattenTypeIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LiveArticle",
          "kind": "LinkedField",
          "name": "liveArticle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "8558ce4288d97a475a488634ea51dc85";

export default node;
