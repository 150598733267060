/**
 * @generated SignedSource<<3448104615758988b0854b82c2c51311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersIsArticleVideoAsHeroArticle$data = {
  readonly multimediaEmbed: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "helpersIsArticleVideoAsHeroArticle";
};
export type helpersIsArticleVideoAsHeroArticle$key = {
  readonly " $data"?: helpersIsArticleVideoAsHeroArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersIsArticleVideoAsHeroArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersIsArticleVideoAsHeroArticle"
};

(node as any).hash = "1029262c60e0e25ec8e8fe2d7359a364";

export default node;
