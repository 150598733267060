/**
 * @generated SignedSource<<73c5bfc19f2605876516f8540dacfce7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentPublishedDateContent$data = {
  readonly publishedDate: number | null | undefined;
  readonly " $fragmentType": "contentPublishedDateContent";
};
export type contentPublishedDateContent$key = {
  readonly " $data"?: contentPublishedDateContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentPublishedDateContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentPublishedDateContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "818906dd57c503c753e6c02c23a554f1";

export default node;
