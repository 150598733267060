/**
 * @generated SignedSource<<445e1905b99b9d3a2e34478314b50921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentCoverVideoContent$data = {
  readonly multimediaEmbed?: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
  readonly " $fragmentType": "contentCoverVideoContent";
};
export type contentCoverVideoContent$key = {
  readonly " $data"?: contentCoverVideoContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentCoverVideoContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentCoverVideoContent",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "multimediaEmbed",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "json",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentSchemaRenderContent"
        }
      ],
      "type": "Article",
      "abstractKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "452beedbc6c29a93dab11d68ca7930c4";

export default node;
