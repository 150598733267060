import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, readInlineData, useFragment } from "react-relay";

import { checkIsSuperTopic } from "scmp-app/components/topic";
import type { Props as TopicLinkProps } from "scmp-app/components/topic/topic-link";
import type { contentTopicLinkContent$key } from "scmp-app/queries/__generated__/contentTopicLinkContent.graphql";
import type { contentTopicLinkSuperTopic$key } from "scmp-app/queries/__generated__/contentTopicLinkSuperTopic.graphql";

import { StyledTopicLink } from "./styles";

export type Variant =
  | { superTopicReference: contentTopicLinkSuperTopic$key; type: "sub-topic" }
  | { type: "main" }
  | { type: "secondary" };

type Props = Omit<TopicLinkProps, "reference"> & {
  className?: string;
  currentTopicReference?: string;
  reference: contentTopicLinkContent$key;
  variant: Variant;
};

export const ContentTopicLink: FunctionComponent<Props> = ({
  currentTopicReference,
  reference: reference_,
  variant,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentTopicLinkContent on Content {
        topics {
          urlAlias
          ...entityLink
          ...topicLinkTopic
        }
      }
    `,
    reference_,
  );

  if (!content.topics) return null;

  switch (variant.type) {
    case "main": {
      const mainTopic = first(content.topics);
      if (!mainTopic) return null;
      return <StyledTopicLink {...props} reference={mainTopic} />;
    }

    case "secondary": {
      if (content.topics.length <= 1) return null;
      const secondaryTopic = first(
        content.topics.filter(item => item?.urlAlias !== currentTopicReference),
      );
      if (!secondaryTopic) return null;
      return <StyledTopicLink {...props} reference={secondaryTopic} />;
    }

    case "sub-topic":
      const topic = readInlineData<contentTopicLinkSuperTopic$key>(
        graphql`
          fragment contentTopicLinkSuperTopic on Topic @inline {
            ...topicCheckSuperTopic
            urlAlias
            relatedLink {
              url
            }
          }
        `,
        variant.superTopicReference,
      );

      const isSuperTopic = checkIsSuperTopic(topic);
      const relatedLinks = new Set(
        topic.relatedLink
          ?.map(item => item?.url)
          .filter(element => notEmpty(element) && element !== topic.urlAlias),
      );
      if (!isSuperTopic || relatedLinks.size === 0) return null;

      const mainSubTopic = content.topics.find(
        item => item?.urlAlias && relatedLinks.has(item.urlAlias),
      );
      if (!mainSubTopic) return null;

      return <StyledTopicLink reference={mainSubTopic} {...props} />;
  }
};

ContentTopicLink.displayName = "ContentTopicLink";
