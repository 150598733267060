import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import IconScmpLogo from "./icon-scmp-logo.svg";

export const Placeholder = styled(IconScmpLogo)``;

export type ContainerProps = {
  $aspectRatio?: string;
  $aspectRatioDesktopUp?: string;
  $aspectRatioTabletUp?: string;
};
export const Container = styled.figure<ContainerProps>`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  block-size: 100%;

  background: #dedede;

  ${Placeholder} {
    inline-size: 45%;
    block-size: 45%;
  }

  ${props => css`
    ${props.$aspectRatio &&
    css`
      aspect-ratio: auto ${props.$aspectRatio};
    `};

    ${theme.breakpoints.up("tablet")} {
      ${props.$aspectRatioTabletUp &&
      css`
        aspect-ratio: auto ${props.$aspectRatioTabletUp};
      `};
    }

    ${theme.breakpoints.up("desktop")} {
      ${props.$aspectRatioDesktopUp &&
      css`
        aspect-ratio: auto ${props.$aspectRatioDesktopUp};
      `};
    }
  `}
`;
