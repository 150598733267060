/**
 * @generated SignedSource<<f49de1129e29ce98e37d1a352f1837ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentReadingTimeContent$data = {
  readonly flag: string | null | undefined;
  readonly readingTime: number | null | undefined;
  readonly " $fragmentType": "contentReadingTimeContent";
};
export type contentReadingTimeContent$key = {
  readonly " $data"?: contentReadingTimeContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentReadingTimeContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentReadingTimeContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readingTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flag",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "9d4d02292bd12cc0173378ba7d298c84";

export default node;
